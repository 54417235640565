<template>
  <div class="liveCampaigns">
    <van-pull-refresh v-model="loading" @refresh="getList" pulling-text="Pull to refresh" loosing-text="Loose to refresh" loading-text="Loading...">
      <div class="container">

        <!-- <h4>All Live Campaigns</h4> -->

        <div v-if="allCampaignData.length">
          <div class="card d-flex" @click="gotoDetails(item)" v-for="item in allCampaignData" :key="item.campaign_id">

            <van-image :src="item.avatar" fit="cover" width="140" />

            <div class="metadata">
              <p class="title"><strong>{{ item.campaign_name }}</strong></p>
              <p class="title"><small>{{ item.start_date | dateFormatMonth }} ~ {{ item.end_date | dateFormatMonth }}</small></p>

              <img v-if="item.promo_platform === 1" src="@/assets/images/icon-youtube.svg" width="18" class="icon platform" />
              <img v-if="item.promo_platform === 2" src="@/assets/images/icon-instagram.svg" height="16" class="icon platform" />

              <van-divider></van-divider>

              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <img src="@/assets/images/icon-announce.svg" height="14" class="icon" />
                  {{ item.campaign_type | campaignType }}
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/images/icon-rupee.svg" height="14" class="icon" />
                  {{ item.budget | formatNumber }}
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/images/icon-user-group.svg" height="10" class="icon" />
                  Min {{ item.min_follower | formatNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center" style="margin: 50px 0 0;" v-if="loading">
          <van-loading color="#f54153" />
        </div>

        <van-empty description="No live campaigns found!" v-if="!loading && !allCampaignData.length" image="https://influencer.soocel.com/img/no-campaign.f391a06e.png">
        </van-empty>

      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getList } from '@/api/campaign'
import constantData from '@/utils/constant'

export default {
  name: 'Dashboard',
  data () {
    return {
      activeTab: 0,
      influencerstatus: constantData.influencerstatus,
      params: {
        page: 1,
        size: 10
      },
      totalPages: 0,
      loading: true,
      allCampaignData: [],
      myCampaignData: [],
      channelDialogue: false,
      campData: {},
      channelIds: [],
      mediaId: [],
      totalApprovedCampaign: 0,
      summery: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      this.loading = true
      const data = await getList(this.params)
      if (data.success) {
        this.allCampaignData = []
        this.allCampaignData = data.campaigns.map(item => {
          item.category = item.category ? item.category.split(',') : []
          item.language = item.language ? item.language.split(',') : []
          item.gender = item.gender ? item.gender.split(',') : []
          return item
        })
        this.totalPages = Math.ceil(data.total_count / 10)
      } else {
        if (window.Toaster) {
          window.Toaster.postMessage(data.message)
        }
        this.allCampaignData = []
      }
      this.loading = false
    },

    gotoDetails (campaign) {
      if (window.GoToUrl) {
        // window.GoDetails.postMessage(JSON.stringify({ campaignId: campaign.campaign_id, isApply: campaign.is_apply, id: campaign.id }))
        const url = `${window.location.origin}/campaign/details/${campaign.campaign_id}`
        window.GoToUrl.postMessage(JSON.stringify({ url: url, title: 'Campaign Details' }))
      } else {
        this.$router.push(`/campaign/details/${campaign.campaign_id}`)
      }
    }
  }
}
</script>

<style scoped>
  .about {
    background-color: #fafafa;
    min-height: 100vh;
  }
  .container {
    min-height: 100vh;
  }
  /* Tabs */
  .van-tabs >>> .van-sticky {
    box-shadow: 0px -4px 8px 2px rgba(0, 0, 0, 0.4);
    margin: 0 0 5px;
  }
  .van-tabs >>> .van-tab {
    font-size: 14px;
    padding: 0 15px;
    flex: none;
  }
  .van-tabs >>> .van-tabs__line {
    /* width: 20px; */
    /* bottom: 20px; */
    height: 4px;
    background: #f54153;
    border-radius: 2px;
  }
  .van-tabs >>> .van-tab--active {
    font-weight: normal;
    color: #1f2964;
  }

  h4 {
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  /* Card */
  .card {
    position: relative;
  }
  /* .card >>> .van-image {
    margin: 0 0 8px;
  } */
  .van-image, .van-image >>> img {
    border-radius: 14px 0 0 14px;
  }
  .van-divider {
    margin: 10px 0;
  }
  .card p {
    margin: 0 0 5px;
    color: #464646;
  }
  .card p strong {
    font-weight: 500;
  }
  .card .metadata {
    font-size: 10px;
    padding: 10px;
    width: 100%;
  }
  .card .metadata p {
    margin: 0;
  }
  .card .metadata p.title {
    margin: 0 10px 0 0;
    font-size: 14px;
    text-transform: capitalize;
  }
  .card .metadata p small {
    opacity: 0.5;
  }
  .icon {
    margin: 0 5px 0 0;
  }
  .platform {
    margin: 0;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  /* .tag {
    font-size: 10px;
    padding: 0 5px;
    margin: 0 0 2px;
    border-radius: 15px;
  }
  .tag.pending {
    border: 1px solid #fb8c00;
    color: #fb8c00;
  }
  .tag.approved {
    border: 1px solid #4caf50;
    color: #4caf50;
  }
  .tag.rejected {
    border: 1px solid #ff5252;
    color: #ff5252;
  } */

</style>
